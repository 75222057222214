import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "globals/store/persistent";
import createLogger from "vuex/dist/logger";
import createOfflinePunchPlugin from "globals/store/offlinePunchPlugin";
import createOfflineMessagePlugin from "mobile/store/offlineMessagePlugin";
import auth from "./modules/auth";
import data from "./modules/data";
import general from "./modules/general";
import { getDataStore, initLocalForage } from "globals/store/local";

initLocalForage("infopunch", "infopunch-app");

Vue.use(Vuex);

// Disable logs & strict mode in production:
const debug = process.env.NODE_ENV !== "production";

// Plugins for both `development` & `production` modes:
const plugins = [
  createOfflinePunchPlugin(),
  createOfflineMessagePlugin(),
  createPersistedState({
    storage: getDataStore(),
    key: "vuex-persist", //use to delete
    strictMode: true, //will call
    // Specify here which modules should be persistent:
    modules: ["data", "auth"]
  })
];

// Plugins for `development` mode:
const devPlugins = [
  // Integrates with Vue Devtools:
  //todo createLogger()
];

// Plugins for `production` mode:
const prodPlugins = [];

// Store is where all the actions, and data are stored (save)
const store = new Vuex.Store({
  modules: {
    auth,
    data,
    general
  },
  strict: debug,
  plugins: debug ? [...plugins, ...devPlugins] : [...plugins, ...prodPlugins]
});

//make it available to all .js
window.vueStore = store;

export default store;
