<template>
  <header class="px-2 header bg-green">
    <div class="header--content">
      <div class="flex flex-row justify-between items-center">
        <router-link
          class="block"
          to="/">
          <img
            alt="Info Punch Logo"
            :src="logo"
            class="block h-6" />
        </router-link>
        <div
          v-if="$route.name !== 'login'"
          class="flex flex-row justify-start items-center">
          <router-link
            v-if="mobileChatActive"
            :to="ROUTES.MESSAGES"
            class="bg-grey rounded-full flex flex-col items-center justify-center h-6 w-6 p-1 mr-2 relative">
            <inline-icon name="message" />

            <span
              v-if="hasMessageNotification"
              class="absolute top-0 right-0 bg-light rounded-full w-2 h-2 z-1"></span>
          </router-link>

          <div class="relative">
            <button
              type="button"
              @click.prevent="toggleUserDropdown"
              class="bg-grey rounded-full flex flex-col items-center justify-center h-6 w-6">
              <i class="fa fa-user"></i>
            </button>
            <div
              class="user-dropdown"
              @click.stop=""
              v-if="showUserDropdown">
              <button
                type="button"
                class="btn--dropdown"
                @click.prevent="goToIndex">
                {{ STRINGS.MENU_INDEX }}
              </button>
              <a
                class="btn--dropdown"
                target="_blank"
                @click.prevent="userInfoClick">
                {{ STRINGS.MENU_PROFILE }}
              </a>
              <button
                type="button"
                class="btn--dropdown"
                @click.prevent="refreshDataBtn">
                {{ STRINGS.MENU_REFRESH }}
              </button>
              <button
                v-if="showPunchBtn"
                type="button"
                class="btn--dropdown"
                @click.prevent="goToPunch">
                {{ STRINGS.MENU_TIMER }}
              </button>
              <button
                v-if="mobileChatActive"
                type="button"
                class="btn--dropdown"
                @click.prevent="messages">
                {{ STRINGS.MENU_MESSAGES }}
              </button>
              <button
                type="button"
                class="btn--dropdown"
                @click.prevent="logout">
                {{ STRINGS.MENU_LOGOUT }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Modal
      v-if="showOfflineModal"
      @close="showOfflineModal = false">
      <p>{{ STRINGS.NO_INTERNET_POPUP }}</p>
    </Modal>
  </header>
</template>

<script>
import logo from "globals/assets/img/logo-infopunch.png";
import { ROUTES } from "mobile/router/routes";
import Modal from "globals/components/modals/Modal";
import DataAPI from "globals/services/DataAPI";
import Chat from "mobile/views/Chat";
import * as types from "mobile/store/types";
import InlineIcon from "globals/components/InlineIcon";
import Eventbus, { EVENTS } from "globals/utils/eventbus";

export default {
  name: "vue-header",
  components: { InlineIcon, Modal },
  data() {
    return {
      logo,
      showUserDropdown: false,
      showOfflineModal: false,

      forceShowNotification: false
    };
  },

  created() {
    Eventbus.$on(EVENTS.NEW_MESSAGE, this.onNewMessage);
  },
  beforeDestroy() {
    this.userDropdownOFF(); //make sure we remove listener
    Eventbus.$off(EVENTS.NEW_MESSAGE, this.onNewMessage);
  },

  computed: {
    mobileChatActive() {
      if (!this.$store.state.data.parameters) return false;
      return this.$store.state.data.parameters.mobileChatActive;
    },
    hasMessageNotification() {
      return this.$store.state.data.threads.some((t) => t.HasNotification !== "0") || this.forceShowNotification;
    },
    parameters() {
      return this.$store.state.data.parameters;
    },
    showPunchBtn() {
      if (!this.$store.state.auth.user) return false;
      if (!this.parameters) return false;
      return this.parameters.AppMobileUsed && this.$store.state.auth.user.MobilePunch;
    }
  },
  methods: {
    onNewMessage(hasNewMessage) {
      this.forceShowNotification = hasNewMessage;
    },
    userDropdownON() {
      this.showUserDropdown = true;

      this.dropdownId = setTimeout(() => {
        document.body.addEventListener("click", this.userDropdownOFF);
      }, 400); //wait for the event to propagate before adding the listener
    },
    toggleUserDropdown(e) {
      e.stopPropagation();
      if (this.showUserDropdown) {
        this.userDropdownOFF();
      } else {
        this.userDropdownON();
      }
    },
    userDropdownOFF() {
      clearTimeout(this.dropdownId);
      this.showUserDropdown = false;

      document.body.removeEventListener("click", this.userDropdownOFF);
    },
    btnClick() {
      this.userDropdownOFF();
    },
    refreshDataBtn() {
      this.userDropdownOFF();

      //timer
      this.refreshTimerData();

      //CHAT
      this.$store.dispatch(types.GET_UNREAD_MESSAGES, this.$store.getters.userUniqueId);
      this.$store.dispatch(types.GET_THREADS, this.$store.getters.userUniqueId);
      this.$store.dispatch(types.GET_EMPLOYEES);
    },
    logout() {
      this.userDropdownOFF();
      //Make sure we keep all actions so we can send them when back online
      if (
        this.$store.state.data.punchsMadeOffline.length > 0 ||
        this.$store.state.data.messagesMadeOffline.length > 0
      ) {
        this.showOfflineModal = true;
        return;
      }

      this.$router.push(ROUTES.LOGIN).catch(() => {});
    },
    messages() {
      this.userDropdownOFF();
      this.$router.push(ROUTES.MESSAGES).catch(() => {});
    },
    goToIndex() {
      this.userDropdownOFF();
      this.$router.push(ROUTES.INDEX).catch(() => {});
    },
    goToPunch() {
      this.userDropdownOFF();
      this.$router.push(ROUTES.TIMER).catch(() => {});
    },
    userInfoClick() {
      //so the event click dont go to the document
      this.userDropdownOFF();
      const url = `${this.$store.state.data.sitePath}consult-mobile.asp?QLangue=fr&pinOverride=${this.$store.getters.userNIP}&noEmployeOverride=${this.$store.getters.userId}`;

      //open new page
      open(url, "_blank");
    }
  }
};
</script>

<style lang="scss">
.header--content {
  @apply py-4;

  * {
    color: theme("colors.light");
  }
}

.user-dropdown {
  @apply absolute right-0 shadow-lg;
  top: 30px;
  z-index: 9;
}
</style>
