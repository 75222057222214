<template>
  <div id="app">
    <loading-container
      :is-loading="!isReady"
      class="min-h-screen">
      <div
        class="w-full min-h-screen"
        v-if="isReady">
        <is-offline />
        <vue-header />
        <loading-container
          :is-loading="isAppLoading"
          class="w-full h-full">
          <router-view></router-view>
        </loading-container>
        <vue-footer />
        <!-- <modal-quiz
           @close="closeQuiz"
           v-if="showQuiz"></modal-quiz> -->
      </div>
    </loading-container>
  </div>
</template>

<script>
import * as types from "mobile/store/types";
import VueHeader from "mobile/components/VueHeader";
import LoadingContainer from "globals/components/LoadingContainer";
import VueFooter from "mobile/components/VueFooter";
import IsOffline from "mobile/components/IsOffline";
import { ROUTES } from "mobile/router/routes";
import EventBus, { EVENTS } from "globals/utils/eventbus";
import isOnlineMixin from "globals/config/isOnlineMixin";
import pollMessagesMixin from "globals/config/pollMessagesMixin";
import pollParamsDataMixin from "globals/config/pollParamsDataMixin";

export default {
  name: "MobileApp",
  components: { VueFooter, LoadingContainer, VueHeader, IsOffline },

  mixins: [isOnlineMixin, pollMessagesMixin, pollParamsDataMixin],
  data() {
    return {
      isAppLoading: false
    };
  },
  watch: {
    //check for restricted url without being connected
    isReady() {
      this.$nextTick(() => {
        if (this.$route.meta.requireAuth && this.isReady && !this.$store.getters.isLogin) {
          this.$router.replace(ROUTES.LOGIN);
        }
      });
    }
  },
  computed: {
    isReady() {
      return this.$store.state.auth.tokenChecked;
    }
    /*
    showQuiz() {
      let params = this.$store.state.data.parameters;
      return this.$store.state.data.showQuiz && this.$store.getters.isLogin && params.Covid;
    } */
  },
  /* beforeRouteEnter(to, from, next) {
     if (this.isReady && to.meta && to.meta.requireAuth && !window.vueStore.getters.isLogin) {
       console.log('skip')
       next(ROUTES_DEFINITION.LOGIN)
       return
     }
     next()
   }, */
  created() {
    EventBus.$on(EVENTS.APP_LOADER, this.setAppLoader);
  },
  beforeDestroy() {
    EventBus.$off(EVENTS.APP_LOADER, this.setAppLoader);
  },
  mounted() {
    if (this.$store.state.general.isTouchDevice) {
      document.body.classList.add("is-touch");
    }
  },
  methods: {
    setAppLoader(isLoading) {
      this.isAppLoading = isLoading;
    }
    /* closeQuiz() {
       this.$store.dispatch(types.TOGGLE_QUIZ, false);
     } */
  }
};
</script>
