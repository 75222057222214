import * as types from "mobile/store/types";
import AuthAPI from "globals/services/AuthAPI";
import merge from "lodash-es/merge";
import { isEmpty } from "globals/utils/utils";
import moment from "moment";
import { capitalize } from "globals/utils/utils";
import { LANGS, STRINGS } from "globals/utils/constant";
import { ROUTES } from "mobile/router/routes";
import EventBus, { EVENTS } from "globals/utils/eventbus";
import { relogin } from "globals/store/relogin";
import {
  createUserMobileDeviceId,
  getFromStorage,
  getUserMobileDeviceIdFromStorage,
  saveMobileDeviceId,
  setMobileDeviceId
} from "globals/store/mobileDeviceId";
// Static

// initial state
const initialState = {
  companyId: "",
  user: null,
  token: {},
  tokenChecked: false,
  lang: LANGS.FR,
  version: "1.0.3"
};

// getters, make function easy to access by vue
const getters = {
  userNIP: (state) => (!isEmpty(state.user) ? state.user.Nip : ""),
  userId: (state) => (!isEmpty(state.user) ? state.user.UserId : ""),
  userUniqueId: (state) => (!isEmpty(state.user) ? (state.user.employeeUniqueId || "").toString() : ""),
  fullName: (state) => (!isEmpty(state.user) ? capitalize((state.user.Name || "").toLowerCase()) : ""),
  isLogin: (state) => {
    if (isEmpty(state.user)) {
      return false;
    }

    if (isEmpty(state.user.UserId)) {
      return false;
    }
    return true;
  } //make sure we have data
};

// actions
const actions = {
  [types.LOGIN](store, payload) {
    return new Promise((resolve, reject) => {
      AuthAPI.loginUser(payload.companyId)
        .then((data) => {
          if (isEmpty(data.access_token)) {
            store.commit(types.ERROR_MESSAGE, STRINGS(store.rootState.auth.lang).ERROR_PROBLEM);
            reject({ error: STRINGS(store.rootState.auth.lang).ERROR_PROBLEM });
            return data;
          }
          let loginData = merge(
            {},
            {
              user: {},
              token: data.access_token,
              expireIn: data.expires_in
            },
            payload
          );

          AuthAPI.setBearer(data.access_token);
          store.commit(types.LOGIN, loginData);

          //get user data
          store
            .dispatch(types.LOAD_USER_DATA, payload.nip)
            .then((data) => {
              store.commit(types.LOGIN, loginData);

              resolve(data);
            })
            .catch((err) => {
              reject(err);
            });
        })
        .catch((err) => {
          if (err && err.data && err.data.error === "invalid_grant") {
            store.commit(types.ERROR_MESSAGE, STRINGS(store.rootState.auth.lang).ERROR_LOGIN_VERIFY);
          }
          reject(err);
        });
    });
  },
  [types.GET_USER_PARAMETERS](store, nip) {
    return AuthAPI.getUserParams(nip)
      .then((data) => {
        if (data.CommunicationLanguage) {
          let lang = data.CommunicationLanguage.toLowerCase();
          if (lang === "francais") {
            store.dispatch(types.SET_LANG, "fr");
          } else {
            store.dispatch(types.SET_LANG, "en");
          }
        }

        store.commit(types.GET_USER_PARAMETERS, data);
        if (data.LastPunch) {
          store.commit(types.SAVE_LAST_PUNCH, data.LastPunch);
        }
        return data;
      })
      .catch((err) => {
        return null;
      });
  },
  [types.LOGOUT](store) {
    AuthAPI.resetBearer();
    store.commit(types.LOGOUT);
  },
  [types.SET_LANG](store, lang) {
    if (!Object.values(LANGS).includes(lang)) {
      // eslint-disable-next-line no-console
      console.error("NOT VALID LANG", lang);
      return lang;
    }

    store.commit(types.SET_LANG, lang);
  }
};

// mutations
const mutations = {
  [types.RESTORE_MUTATION](state, payload) {
    let excludes = ["tokenChecked"];

    if (!payload) {
      state.tokenChecked = true;
      return;
    }
    if (!payload.auth) {
      window.vueRouter.replace(ROUTES.LOGIN).catch(() => {});
      state.tokenChecked = true;
      return;
    }

    //set state
    //only if same version
    if (payload.auth.version && payload.auth.version === initialState.version) {
      Object.keys(payload.auth).forEach((key) => {
        if (!isEmpty(payload.auth[key])) {
          if (!excludes.includes(key)) {
            state[key] = JSON.parse(JSON.stringify(payload.auth[key]));
          }
        }
      });
    }

    //is empty, reset in axios, go to login and return
    //without those information, we cant relogin
    if (isEmpty(state.companyId) || isEmpty(state.token) || isEmpty(state.user)) {
      AuthAPI.resetBearer();

      //catch so we dont get a error
      window.vueRouter.replace(ROUTES.LOGIN).catch(() => {});
      state.tokenChecked = true;
      return;
    }

    //set bearer token
    let tokenExpireAt = moment(state.token.expireAt);
    let now = moment();
    let isExpire = now.isAfter(tokenExpireAt);

    //relogin the user, that will reload everything
    if (isExpire) {
      AuthAPI.resetBearer();
      relogin(
        state.companyId,
        state.user.Nip,
        () => {},
        (err) => {
          //error with relogin, just logout everything
          window.vueStore.dispatch(types.LOGOUT);
          window.vueRouter.replace(ROUTES.LOGIN).catch(() => {});
        }
      );
      return;
    }

    //token is OK, use it
    AuthAPI.setBearer(state.token.bearer);
    state.tokenChecked = true;
  },
  [types.GET_USER_PARAMETERS](state, userData) {
    state.user = userData;

    if (userData) {
      let userDeviceId = createUserMobileDeviceId(state.companyId, userData.Nip);
      //if new phone, init with user
      if (!window.__userMobileDeviceId__) {
        saveMobileDeviceId(userDeviceId);
      }
      //if we never init this user, save the user device id in backend
      if (!userData.QDeviceCookie) {
        AuthAPI.setUserMobileDeviceId(userData.Nip, userDeviceId);
      }
    }
  },
  [types.LOGIN](state, payload) {
    let tokenExpireAt = moment().add(payload.expireIn, "seconds").format();

    state.companyId = payload.companyId;
    state.token.bearer = payload.token;
    state.token.expireAt = tokenExpireAt;

    state.tokenChecked = true;
  },
  [types.SET_LANG](state, lang) {
    state.lang = lang;
  },
  [types.LOGOUT](state) {
    let excludes = ["companyId", "tokenChecked"];

    Object.keys(state).forEach((key) => {
      if (!excludes.includes(key)) {
        try {
          state[key] = JSON.parse(JSON.stringify(initialState[key]));
        } catch (e) {}
      }
    });
  }
};

export default {
  namespaced: false,
  state: merge({}, initialState), //make sure its not the same object
  getters,
  actions,
  mutations
};
