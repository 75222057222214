import * as types from "mobile/store/types";
import isEqual from "lodash-es/isEqual";
import EventBus, { EVENTS } from "globals/utils/eventbus";

const pollParamsDataMixin = {
  created() {
    this.paramsPollingId = null;

    this.refreshParametersLastUpdate();

    EventBus.$on(EVENTS.CHECK_FOR_UPDATED_DATA, this.refreshParametersLastUpdate);
  },
  watch: {
    "$store.getters.isLogin"(isLogin) {
      if (!isLogin) {
        this.stopParamsPolling();
        return;
      }
      this.$nextTick(() => {
        this.refreshParametersLastUpdate();
      });
    },
    "$store.state.general.isOnline"(isOnline) {
      //can only create thread when online
      this.canCreateThread = isOnline;

      //polling stuff
      if (isOnline) {
        this.stopParamsPolling();
        this.startParamsPolling();
      } else {
        //if no internet stop polling
        this.stopParamsPolling();
      }
    }
  },
  beforeDestroy() {
    this.stopParamsPolling();
    EventBus.$off(EVENTS.CHECK_FOR_UPDATED_DATA, this.refreshParametersLastUpdate);
  },
  methods: {
    startParamsPolling() {
      this.paramsPollingId = setInterval(() => {
        this.refreshParametersLastUpdate();
      }, 10000); //10 SEC
    },
    stopParamsPolling() {
      clearInterval(this.paramsPollingId);
    },
    refreshParametersLastUpdate() {
      if (!this.$store.getters.userId) return;
      if (!this.$store.getters.isLogin) return;
      const oldValue = this.$store.getters.parametersLastUpdate;

      //if value changed, refresh timer data
      return this.$store.dispatch(types.GET_PARAMETERS_LAST_UPDATE, this.$store.getters.userId).then((nextValue) => {
        if (!oldValue) return;
        if (isEqual(nextValue, oldValue)) return; //if the value is the same, do nothing
        this.refreshTimerData();
      });
    }
  }
};

export default pollParamsDataMixin;
