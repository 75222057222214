import * as types from "mobile/store/types";

const pollMessagesMixin = {
  created() {
    this.messagePollingId = null;

    this.refreshMessages();
  },
  watch: {
    "$store.getters.isLogin"(isLogin) {
      if (!isLogin) {
        this.stopMessagesPolling();
        return;
      }
      this.$nextTick(() => {
        this.refreshMessages();
      });
    },
    "$store.state.general.isOnline"(isOnline) {
      //can only create thread when online
      this.canCreateThread = isOnline;

      //polling stuff
      if (isOnline) {
        this.stopMessagesPolling();
        this.startMessagesPolling();
      } else {
        //if no internet stop polling
        this.stopMessagesPolling();
      }
    }
  },
  beforeDestroy() {
    this.stopMessagesPolling();
  },
  methods: {
    startMessagesPolling() {
      this.messagePollingId = setInterval(() => {
        this.refreshMessages();
      }, 10000); //10 SEC
    },
    stopMessagesPolling() {
      clearInterval(this.messagePollingId);
    },
    refreshMessages() {
      if (!this.$store.getters.isLogin) return;
      if (!this.$store.getters.userUniqueId) return;

      return Promise.all([
        this.$store.dispatch(types.GET_UNREAD_MESSAGES, this.$store.getters.userUniqueId),
        this.$store.dispatch(types.GET_THREADS, this.$store.getters.userUniqueId)
      ]);
    }
  }
};

export default pollMessagesMixin;
