<template>
  <div
    v-if="showPopup"
    class="is-offline">
    <p class="text-center">{{ STRINGS.NO_INTERNET }}</p>
  </div>
</template>

<script>
export default {
  name: "is-offline",
  computed: {
    showPopup() {
      return (
        !this.$store.state.general.isOnline &&
        (this.$store.state.data.punchsMadeOffline.length > 0 || this.$store.state.data.messagesMadeOffline.length > 0)
      );
    }
  }
};
</script>

<style lang="scss">
.is-offline {
  @apply w-full bg-red p-2;

  p {
    @apply text-light;
  }
}
</style>
