import Vue from "vue";
import MobileApp from "./MobileApp";
import router from "./router";
import store from "./store";
import "globals/config/registerServiceWorker";
import "globals/vendors/gsap";
import "globals/assets/scss/main.scss";
import "globals/config/mixins";
import "globals/config/filters";

Vue.config.productionTip = false;

//clear cache timeout
if (typeof window.cachesCleanUpTimeoutID !== "undefined") {
  clearTimeout(window.cachesCleanUpTimeoutID);
  delete window.cachesCleanUpTimeoutID;
}

window.appType = APP_TYPES.MOBILE;

//set moment fr
import "globals/utils/moment-fr";
import "moment";
import { APP_TYPES } from "globals/utils/constant";

new Vue({
  router,
  store,
  render: (h) => h(MobileApp)
}).$mount("#app");

if (process.env.NODE_ENV === "production" && typeof window.Sentry !== "undefined") {
  window.Sentry.init({
    dsn: "https://31a927818d984d5f8d92c564ca80efbf@sentry.io/1836943",
    integrations: [new window.Sentry.Integrations.Vue({ Vue, attachProps: true })]
  });
}

window.Vue = Vue;
