import EventBus, { EVENTS } from "globals/utils/eventbus";
import * as types from "mobile/store/types";

export default function createOfflineMessagePlugin() {
  const INCLUDES = [types.CREATE_MESSAGE];
  return (store) => {
    store.subscribeAction((action) => {
      if (!INCLUDES.includes(action.type)) return;
      if (store.state.general.isOnline) return;
      store.dispatch(types.ADD_TO_OFFLINE_MESSAGE, action.payload);
    });

    EventBus.$on(EVENTS.IS_ONLINE, (_isOnline) => {
      if (_isOnline) {
        store.dispatch(types.MAKE_ALL_OFFLINE_MESSAGES);
      }
    });
  };
}
